@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
@font-face {
  font-family: 'Artigiano Display';
  src: url(/static/media/ArtigianoDisplay-Regular.2925c609.woff2) format('woff2'),
    url(/static/media/ArtigianoDisplay-Regular.e4e675d1.woff) format('woff'),
    url(/static/media/ArtigianoDisplay-Regular.84d27b52.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

