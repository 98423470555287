@font-face {
  font-family: 'Artigiano Display';
  src: url('./fonts/ArtigianoDisplay-Regular.woff2') format('woff2'),
    url('./fonts/ArtigianoDisplay-Regular.woff') format('woff'),
    url('./fonts/ArtigianoDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
